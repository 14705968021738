/* tailwind imports */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Roboto font */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.banner {
    height: 304px;
}

.product_img {
    height: 152px;
    width: 152px;
}

.description_top {
    letter-spacing: 0px;
    text-align: center;
    font: normal normal normal 40px/60px PT Serif;
    letter-spacing: 0px;
    opacity: 1;
}

.description_low {
    letter-spacing: 0px;
    opacity: 1;
    line-height: normal;
}

.description_low ul {
    list-style-type: disc;
    padding-left: revert;
}

.description_low a {
    text-decoration: underline;
    color: #EABFBD;
}

.font-roboto {
    font-family: 'Roboto', sans-serif !important;
}

.font-serif {
    font-family: 'PT Serif', serif !important;
}

.box_shadow_input {
    box-shadow: 1px 2px 4px #e6e6e6;
    border-radius: 15px;
    border: 1px solid #7070701a;
}

.w49 {
    width: 49% !important;
}

.ml2 {
    margin-left: 2% !important;
}

.radio-circle {
    height: 15px;
    width: 15px;
    border-radius: 7.5px;
    border-color: #6b6771;
    border-width: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radio-inner-circle {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background-color: #6b6771;
}

.cookies_options {
    height: 160px;
    width: 316px;
    border-radius: 20px;
    object-fit: cover;
}

button {
    height: 48px;
    width: 220px;
    border: 3px solid #7c8b9c;
    border-radius: 26px;
    letter-spacing: 2.8px;
}

.home-spinner {
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    overflow: auto hidden;
}
.warning_box{
    height: 100.3pt;
    width: 109.72pt;
}

/* ? package overrides */

.react-time-picker__inputGroup{
    flex: 2 !important;
}
.react-time-picker__clock-button{
    flex: 1 !important;
}
.react-date-picker__inputGroup{
    flex: 2 !important;
}
.react-date-picker__calendar-button{
    flex: 1 !important;
}

.react-date-picker__wrapper,
.react-time-picker__wrapper {
    width: 100% !important;
    border: none !important;
}

.react-date-picker__calendar-button,
.react-time-picker__clock-button {
    height: auto !important;
    display: flex;
    justify-content: flex-end;
}


/* ? package overrides */


/* ? package overrides */

.react-time-picker__inputGroup{
    flex: 2 !important;
}
.react-time-picker__clock-button{
    flex: 1 !important;
}
.react-date-picker__inputGroup{
    flex: 2 !important;
}
.react-date-picker__calendar-button{
    flex: 1 !important;
}

.react-date-picker__wrapper,
.react-time-picker__wrapper {
    width: 100% !important;
    border: none !important;
}

.react-date-picker__calendar-button,
.react-time-picker__clock-button {
    height: auto !important;
    display: flex;
    justify-content: flex-end;
}


/* ? package overrides */


/* Tailwind Layers */

@layer components {
    .short_text_input {
        @apply p-4 w-full bg-transparent outline-none font-roboto;
    }
     .short_text_input_date {
        @apply w-full bg-transparent outline-none font-roboto;
    }
    .dropdown_placeholder_cont {
        @apply box_shadow_input bg-white my-4 cursor-pointer flex items-center pr-4
    }
    .social_thankyou_icon {
        @apply h-10 w-10 rounded-full mx-2 border-2 border-clifford flex items-center justify-center cursor-pointer
    }
    .thankyou_description {
        @apply text-robin text-center mx-auto font-serif text-xl md:text-3xl
    }
}


/* Repsonsive css */

@media screen and (max-width: 767px) {
    .banner {
        height: 140px;
    }
    .product_img {
        height: 120px;
        width: 120px;
    }
}

@media screen and (min-width: 768px) {
    .mdw49 {
        width: 49% !important;
    }
    .mdml2 {
        margin-left: 2% !important;
    }
}


/* Loader css */

.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #6B6771;
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.profile {
    height: 152px;
    width: 152px;
}

.social-icons {
    height: 32px;
    width: 32px;
}

@media screen and (min-width: 768px) {
    .mdw49 {
        width: 49% !important;
    }
    .mdml2 {
        margin-left: 2% !important;
    }
}


/* Loader css */

.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #6B6771;
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.profile {
    height: 152px;
    width: 152px;
}

.social-icons {
    height: 32px;
    width: 32px;
}

.questionBreak+.questionBreak {
    display: block;
}

.question-number {
    float:left;
    padding-right: 5px;
}

.question-title {
    overflow: hidden;
}

.modal-buttons Button+Button {
    margin-left: 5px;
}

@media only screen and (max-width: 600px) {
    .modal-buttons Button {
        width: 50%;
    }
}

.invalid {
    border-color: red;
    border-width: thin;
    border-radius: 15px;
}